<template>
  <div>
    <!--<MobileTabbar />-->
  </div>
</template>

<script>
import MobileTabbar from '@/components/mobile/MobileTabbar'
export default {
  name: 'MobileOrderList',
  components: {
    MobileTabbar
  }

}
</script>

<style scoped>

</style>
